import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Spinner,  Container, Form } from 'react-bootstrap';
import '../c_styles/UnidadAcademica.css';
import '../c_styles/Perfil.css';
import Cookies from 'universal-cookie';
import axios from 'axios';
import apiInstance from '../api/ApiInstance';
import imagenUsach from '../assets/logoUsach.svg.png';
const serverUrl = process.env.REACT_APP_SERVER_API;
const ax = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
});


const conectado = new Cookies();

var id_usuario_actual = conectado.get('id');




function Perfil() {

    const [perfil, setPerfil] = useState([]);

    useEffect(() => {
        apiInstance.get(serverUrl+"/getUsuarioById", {params: {id_usuario: id_usuario_actual}})
        .then(response=>{
            setPerfil(response.data[0]);
        })
        .catch(error=>{
            alert(error.response.data.message);
        });
      }, []);
   
    return (  
        <Container className="fondoUnidadAcademica" fluid>
            <Container className="UnidadAcademica" fluid>
                

    <Row>
    
        <h3>Perfil de {perfil[2]+" "+perfil[3]+" "+perfil[4]}</h3>
        
        <Col className="profile-box container" sm={4} style={{textAlign: "left"}}>
            <Row>
            <div> <img   src="https://ruthrumack.com/wp-content/uploads/2019/11/no-avatar.png"></img></div>
            
            </Row>
                    
           
           
            
        </Col>
        
        <Col sm ={4}>
        <form className='dataP'>
            <div className="mb-3">
          <label>Nombres</label>
          <input
            type="text"
            className="form-control"
            placeholder={perfil[2]}
            disabled
          />
        </div>
        <div className="mb-3">
          <label>Apellidos</label>
          <input type="text" className="form-control" placeholder={perfil[3]+ " "+perfil[4]} disabled/>
        </div>
        <div className="mb-3">
          <label>Rut</label>
          <input
            type="text"
            className="form-control"
            placeholder={perfil[0]+"-"+perfil[1]}
            disabled
          />
        </div>
        <div className="mb-3">
          <label>Correo electrónico</label>
          <input
            type="email"
            className="form-control"
            placeholder={perfil[5]}
          disabled/>
        </div>
        <div className="mb-3">
          <label>Telefono</label>
          <input
            type="text"
            className="form-control"
            placeholder={perfil[7]}
            disabled
          />
        </div>
        <Row> 
                
                {/* <Col>
                    <button type="submit" className="btn btn-primary">
                        Modificar Datos
                    </button>
                </Col>  */}
              
              </Row>
        </form></Col>

              
        <Col sm={4}> 
        
        </Col>
      

      </Row>

            </Container>
        </Container>
    );
}

export default Perfil;